import React, { useRef, useState } from "react";

const PsImageSelect = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(props.defaultValue || "");
  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(file);
        setPreviewUrl(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleReset = () => {
    // Clear the file input value
    fileInputRef.current.value = "";
    setSelectedImage(null);
    setPreviewUrl("");
  };

  return (
    <div>
      {previewUrl && (
        <div>
          <img
            src={previewUrl}
            alt="Preview"
            style={props.style || { maxWidth: "120px" }}
          />
          <button
            onClick={handleReset}
            style={{
              position: "absolute",
              background: "red",
              color: "#fff",
              border: "none",
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
      )}
      <input
        type="file"
        name={props.name || "file"}
        accept="image/*"
        ref={fileInputRef}
        onChange={handleImageChange}
      />
    </div>
  );
};

export default PsImageSelect;
