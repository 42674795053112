import { Spin } from "antd";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { PsLabel, formToObject } from "../../../utils";
import { _POST_STUDENT_GRIEVANCE } from "../../../models/iformModel";

const StudentGrivanceForm = (props) => {
  const [loader, setLoader] = useState(false);
  const [validate, setValidate] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidate(true);
      return;
    }
    if (!window.confirm("Do you want to submit ?")) return;
    setLoader(true);
    _POST_STUDENT_GRIEVANCE(formToObject(form)).then((res) => {
      if (res.status == "1") {
        alert(res.message || "Sent");
        form.reset();
      } else {
        alert(res.message || "Unknown Error");
      }
      setLoader(false);
    });
  };

  return (
    <>
      <div className="container py-3">
        <Spin spinning={loader}>
          <Form noValidate validated={validate} onSubmit={handleFormSubmit}>
            <Row>
              <Col md={12}>
                <PsLabel text="Your Name (required) " required />
                <Form.Control
                  type="text"
                  name="student_name"
                  className="fw-bold"
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <PsLabel
                  text="Your Admission No (or) Register No (required) "
                  required
                />
                <Form.Control
                  type="text"
                  name="register_no"
                  className="fw-bold"
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <PsLabel text="Your Batch (required) " required />
                <Form.Control
                  type="text"
                  name="batch"
                  className="fw-bold"
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <PsLabel text="Your Programme (required) " required />
                <Form.Control
                  type="text"
                  name="programme"
                  className="fw-bold"
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <PsLabel text="Your Email (required) " required />
                <Form.Control
                  type="email"
                  name="email"
                  className="fw-bold"
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <PsLabel text="Post your Grievance (required) " required />
                <Form.Control
                  as="textarea"
                  name="grievance"
                  className="fw-bold"
                  rows="8"
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <Button type="submit">SEND</Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </>
  );
};

export default StudentGrivanceForm;
