import React from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import CollectionPieChart from "./collectionPieChart";
import { commaFormatted } from "../../../../../utils";

const ChartTypeWithData = ({ data, dataList }) => {
  const getTotal = () => {
    let total = 0;
    dataList.map(
      (item) =>
        item.amount && (total = parseFloat(total) + parseFloat(item.amount))
    );
    return parseFloat(total).toFixed(2);
  };

  return (
    <div data-aos="zoom-in">
      <Row>
        <Col md={8}>
          {data && data.length > 0 && <CollectionPieChart data={data} />}
        </Col>
        <Col md={4}>
          {dataList.map((item, i) => {
            return (
              <ListGroup className="px-0 mx-0" key={i}>
                <ListGroup.Item className="px-0 mx-0 border-bottom">
                  {item.method_name}
                  <span className="float-end">
                    {commaFormatted(item.amount, true) || "0"}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            );
          })}
          <ListGroup className="px-0 mx-0">
            <ListGroup.Item className="px-0 mx-0 fw-bold">
              Total
              <span className="float-end">
                Rs.{commaFormatted(getTotal(), true)}
              </span>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
};
export default ChartTypeWithData;
