import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import {
    Row,
    Col,
    Button,
    Form,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { Select } from "antd";
import { Spin } from "antd";
import axios from "axios";
import { ServiceUrl } from "../../../../utils/serviceUrl";
import { listUserLogins } from "../../../../models/users";

const EDITNOC = (props) => {
    const [loader, setLoader] = useState(false);
    const [validated, setValidated] = useState(false);
    const [examStaff, setExamStaff] = useState([]);
    const [selUserNames, setSelUserNames] = useState("");

    const buttonRef = useRef(null);
    const handleFormSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
        setLoader(true);
        axios
            .post(ServiceUrl.CERTIFICATE.NOCS.EDIT_NOC_TYPE, new FormData(form))
            .then((res) => {
                if (res["data"].status == "1") {
                    if (props.onSuccess) props.onSuccess(res["data"].data);
                    toast.success(res["data"].message || "Success");
                } else {
                    toast.error(res["data"].message || "Error");
                }
                setLoader(false);
            });
    };
    useEffect(() => {
        console.log('dsource', props.dataSource)
        loadStaffs();
        listUserLogins().then((res) => {
            setLoader(false);
        });

    }, []);

    const loadStaffs = () => {
        var form = new FormData();
        form.append("academic_department", "NOT_NULL");

        axios.post(ServiceUrl.HR.LIST_EMPLOYEES, form).then((res) => {
            if (res["data"].status == "1") {
                setExamStaff(res["data"].data);
            } else {
                toast.error(res["data"].message || "Error");
            }
        });
    };
    const field = (fieldName) => props.dataSource[fieldName] || "";

    return (
        <>
            <Spin spinning={loader}>
                <Form
                    method="post"
                    noValidate
                    validated={validated}
                    id="frm_saveCourse"
                    onSubmit={handleFormSubmit}
                >
                    <input type="hidden" name="noc_type_id" value={field("noc_type_id")} />
                    <Row className="mt-3">
                        <Col md={12}>
                            <Row>
                                <Col md={4}>
                                    <label className="fs-sm">
                                        Type Name:<span className="text-danger">*</span>
                                    </label>
                                </Col>
                                <Col md={7}>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        className="fw-bold"
                                        name="noc_type_name"
                                        required
                                        defaultValue={field("noc_type_name")}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={4}>
                                    <input
                                        type="hidden"
                                        name="approving_employee_ids"
                                        value={selUserNames ? selUserNames.join(",") : ""}
                                    />
                                    <label className="fs-sm">Allowed Employees to approve:
                                        <span className="text-danger">*</span></label>
                                </Col>
                                <Col md={7}>
                                    <Select
                                        showSearch
                                        placeholder="Select  Employees Name"
                                        name="approving_employee_ids"
                                        defaultValue={field("approving_employee_ids") ? field("approving_employee_ids").split(",") : []}
                                        optionFilterProp="children"
                                        mode="multiple"
                                        style={{
                                            width: "100%",
                                        }}
                                        onChange={(list) => setSelUserNames(list)}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <Select.Option value="">Select Employee  Name</Select.Option>
                                        {examStaff.map((item) => {
                                            return (
                                                <Select.Option value={item.id}>
                                                    {" "}
                                                    {item.emp_name}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={4}>
                                    <label className="fs-sm">Can Superior approve NOCs:<span className="text-danger">*</span></label>
                                </Col>
                                <Col md={7}>
                                    <div className="d-flex justify-content-md-start justify-content-between gap-1">
                                        <Form.Check
                                            type="radio"
                                            id="superiorApprovalCheckboxYes"
                                            label="Yes"
                                            className="fw-bold"
                                            name="is_superior_approve"
                                            value="1"
                                            required
                                            defaultChecked={field("is_superior_approve") === "1"}
                                        />
                                        <Form.Check
                                            type="radio"
                                            id="superiorApprovalCheckboxNo"
                                            label="No"
                                            className="fw-bold"
                                            name="is_superior_approve"
                                            value="0"
                                            required
                                            defaultChecked={field("is_superior_approve") === "0"}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={12}>
                                    <div className="text-end">
                                        <Button type="submit" size="sm" ref={buttonRef}>
                                            <i className="fa-solid fa-check me-2"></i> Update
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form >
            </Spin >
        </>
    );
};

export default withRouter(EDITNOC);