import React from "react";
import { capitalizeFirst, lowerCase, upperCase } from ".";

const PsLabel = (props) => {
  const getText = () => {
    let t = "-";
    if (props.text && props.text.length > 0) {
      t = props.text;
      if (props.uppercase) t = upperCase(props.text);
      else if (props.lowecase) t = lowerCase(props.text);
      else if (props.capitalize) t = capitalizeFirst(props.text);
    }
    return t;
  };

  return (
    <label className={`control-label ${props.className || ""}`}>
      {getText()}
      {props.required && <span className="text-danger">*</span>}
    </label>
  );
};

export default PsLabel;
