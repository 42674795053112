import axios from "axios";

export default async function callApi(
  endpoint,
  reqMethod = "get",
  payload,
  callBack = false
) {
  let data = false;
  //console.log(payload);
  if (payload instanceof HTMLFormElement) {
  } else if (typeof payload === "object") {
    data = new FormData();
    Object.keys(payload).map((payloadObj) => {
      data.append(payloadObj, payload[payloadObj]);
      return data;
    });
  }

  const config = {
    method: reqMethod.toLowerCase(),
    url: endpoint,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  //console.log(data);
  //console.log(payload);
  if (reqMethod.toLowerCase() === "post" && (data || payload)) {
    config.data = data || payload;
  }

  return new Promise((resolve, reject) => {
    axios(config)
      .then((response) => {
        if (callBack) {
          if (
            response.data &&
            response.data.status &&
            response.data.status == "1"
          ) {
            if (response.data.data) callBack(response.data.data);
          }
        } else resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
