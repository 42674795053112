import React, { useContext } from "react";
import PsContext from "../context";
import { brandLogo } from ".";

const styles = {
  tableCollapse: {
    borderCollapse: "collapse",
    border: "none",
  },
  borderBottom: {
    borderCollapse: "collapse",
    borderBottom: "1px solid black",
  },
  smallfont: {
    fontSize: "12px",
    paddingBottom: "7px",
  },
};

const PrintHeaderWithLogo = ({
  borderBottom,
  hideView,
  simple,
  heading,
  hideLogo,
}) => {
  const context = useContext(PsContext);

  const getSecondLogo = () => {
    let lo = context.settingValue("college_logo_square_two");
    if (lo && lo.length > 3) {
      return brandLogo(lo);
    } else return false;
  };

  return (
    <div
      className={hideView ? "display-none" : ""}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div>
        {!hideLogo && (
          <img
            src={brandLogo()}
            style={{
              width: simple ? "50px" : "65px",
              height: simple ? "50px" : "65px",
              marginRight:'20px'
            }}
          />
        )}
      </div>
      <div>
        <center>
        <b style={{ fontSize: "15px" }}>
          {context.settingValue("billheader_name")}
        </b>
        <br />
        {context.settingValue("college_affiliation") && (
          <>
            {context.settingValue("college_affiliation")} <br />
          </>
        )}
        {context.settingValue("billheader_addresscity") && (
          <>
            {context.settingValue("billheader_addresscity")}

            <br />
          </>
        )}
        {!simple && context.settingValue("billheader_addresslineone") && (
          <>
            {context.settingValue("billheader_addresslineone")}
            <br />
          </>
        )}
        {context.settingValue("billheader_addresslinetwo") && (
          <>
            {context.settingValue("billheader_addresslinetwo")} <br />
          </>
        )}
        {!simple && context.settingValue("billheader_phone") && (
          <>
            Phone:{"  "}
            {context.settingValue("billheader_phone")}
          </>
        )}
        {!simple && context.settingValue("billheader_email") && (
          <>
            {context.settingValue("billheader_phone") && <>/</>} Mail:{" "}
            {context.settingValue("billheader_email")}
          </>
        )}
        {heading && (
          <div
            style={{
              fontWeight: "600",
              fontSize: "12px",
              paddingTop: "5px",
              paddingBottom: "5px",
              
            }}
          >
            {heading}
          </div>
        )}
        </center>
      </div>
      <div>
        {!hideLogo && getSecondLogo() && (
          <img
            src={getSecondLogo()}
            style={{
              width: simple ? "50px" : "65px",
              height: simple ? "50px" : "65px",
              marginLeft:'20px'
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PrintHeaderWithLogo;
