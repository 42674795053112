import callApi from "../utils/callApi";
import { ServiceUrl } from "../utils/serviceUrl";

export const _TodayCollectionSummary = async (searchData = false) => {
  let url = ServiceUrl.DASHBOARD.TODAY_COLLECTION_SUMMARY;
  return callApi(url, "post", searchData && searchData);
};

export const _ONLINE_COLLECTION_SUMMARY = async (searchData = false) => {
  let url = ServiceUrl.DASHBOARD.ONLINE_COLLECTION_SUMMARY;
  return callApi(url, "post", searchData && searchData);
};
