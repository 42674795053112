import React from "react";
import { Pie } from "@ant-design/plots";

const CollectionPieChart = ({ data }) => {
  const config = {
    appendPadding: 0,
    height: 200,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "outer",
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  return <Pie {...config} />;
};
export default CollectionPieChart;
