import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
    Row,
    Col,
    Card,
    Button,
    Form,
    InputGroup,
} from "react-bootstrap";
import toast from "react-hot-toast";
import {
    CardFixedTop,
    jsonToQuery,
    upperCase,
} from "../../../../utils";
import { Table, Tag } from "antd";
import axios from "axios";
import { ServiceUrl } from "../../../../utils/serviceUrl";
import PsOffCanvasWindow from "../../../../utils/PsOffCanvasWindow";
import ModuleAccess from "../../../../context/moduleAccess";
import { DEFAULT_PAGE_LIST_SIZE } from "../../../../utils/data";
import AddNOC from "./addnoc";
import EDITNOC from "./editnoc";

const NOCs = (props) => {
    const [showAdd, setShowAdd] = useState(false);
    const [dataView, setDataView] = useState([]);
    const [loader, setLoader] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [viewData, setViewData] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: DEFAULT_PAGE_LIST_SIZE,
        }, total: 100,
    });
    useEffect(() => {
        if (!loader) loadData();
    }, [JSON.stringify(tableParams.pagination)]);
    const getRandomuserParams = (params) => ({

    });
    const loadData = () => {
        setLoader(true);
        setDataList([]);
        setDataView([]);
        axios
            .post(
                ServiceUrl.CERTIFICATE.NOCS.LIST_NOC_TYPE +
                "?" +
                jsonToQuery(getRandomuserParams(tableParams))
            )
            .then((res) => {
                if (res["data"].status == "1") {
                    setDataList(res["data"].data);
                    setDataView(res["data"].data);
                    console.log('k', res["data"].data);
                    setTableParams({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: res["data"].count,
                        },
                    });
                } else {
                    toast.error(res["data"].message || "Error");
                }
                setLoader(false);
            });
    };
    const handleDeleteClick = (item) => {
        if (!window.confirm("Do you want to delete?")) {
            return;
        }
        setLoader(true);
        const form = new FormData();
        form.append("noc_type_id", item.noc_type_id);
        axios.post(ServiceUrl.CERTIFICATE.NOCS.REMOVE_NOC_TYPE, form).then((res) => {
            if (res["data"].status == "1") {
                let m = dataList.filter((obj) => obj.noc_type_id != item.noc_type_id);
                setDataList(m);
                setDataView(m);
                toast.success(res["data"].message || "Success");
            } else {
                toast.error(res["data"].message || "Error");
            }
            setLoader(false);
            loadData();
        });
    };
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
        }
    };
    const handleEdit = (item) => {
        setViewData(item);
        setShowEdit(true);
    };
    const handleSearch = (e) => {
        let m = dataList.filter(
            (item) => upperCase(item.noc_type_name).indexOf(upperCase(e.target.value)) > -1
        );

        setDataView(m);
    };
    const columns = [
        {
            title: "S.No",
            dataIndex: "id",
            width: 50,
            align: "center",
            render: (text, record, index) =>
                tableParams.pagination.pageSize * (tableParams.pagination.current - 1) +
                index +
                1,
        },
        {
            title: "Type id",
            dataIndex: "noc_type_id",
        },
        { title: "Type Name", dataIndex: "noc_type_name" },
        { title: "Approved Employees", dataIndex: "employees" },
        {
            title: "Status",
            render: (text, record, index) => (
                <>
                    {parseInt(record.is_superior_approve) === 1 ? (
                        <Tag color="green">YES</Tag>
                    ) : (
                        <Tag color="red">NO</Tag>
                    )}
                </>
            ),
        },
        {
            title: "Manage",
            dataIndex: "id",

            align: "center",
            render: (text, record, index) => (
                <>
                    <ModuleAccess
                        module="admission_allocation"
                        action="action_update"
                    >
                        <div class="btn-group">

                            <Button
                                size="sm"
                                variant="transparent"
                                title="Edit"
                                onClick={(e) => handleEdit(record)}

                            > <i className="fa-solid fa-pen"></i></Button>
                        </div>
                    </ModuleAccess>

                    <ModuleAccess module="nocs" action="action_delete">
                        <Button
                            size="sm"
                            variant="transparent"
                            title=""
                            onClick={(e) => handleDeleteClick(record)}
                        >
                            <i className="fa-solid fa-trash-can fs-6"></i>
                        </Button>
                    </ModuleAccess>
                </>
            ),
        },
    ];

    return (
        <>
            <CardFixedTop title="NOCs">
                <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                        <Button
                            variant="white"
                            className="border-start ms-2"
                            onClick={(e) => setShowAdd(true)}
                        >
                            <i className="fa fa-plus fs-5 px-1"></i> Add NOC
                        </Button>
                    </li>
                </ul>
            </CardFixedTop>
            <div className="container">
                <Row className="mt-3">
                    <Col md={6}>
                        <InputGroup size="sm">
                            <Form.Control
                                size="sm"
                                placeholder="Search by Type Name"
                                onChange={handleSearch}
                            />
                            <InputGroup.Text>
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mt-2">
                        <Card>
                            <Card.Body className="">
                                <div>
                                    <Table
                                        bordered={true}
                                        size="small"
                                        columns={columns}
                                        rowKey={(record) => record.id}
                                        dataSource={dataView}
                                        pagination={tableParams.pagination}
                                        loading={loader}
                                        onChange={handleTableChange}
                                        scroll={{ y: "calc(100vh - 250px)" }}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <PsOffCanvasWindow
                title=" Add NOC"
                onHide={(e) => setShowAdd(false)}
                show={showAdd}

                size="md"
            >
                <AddNOC onSuccess={(d) => { loadData(); setShowAdd(false) }} />
            </PsOffCanvasWindow>
            <PsOffCanvasWindow
                title=" EDIT NOC"
                onHide={(e) => setShowEdit(false)}
                show={showEdit}

                size="md"
            >
                <EDITNOC
                    dataSource={viewData}
                    onSuccess={(e) => {
                        setShowEdit(false);
                        setViewData([]);
                        loadData();
                    }} />
            </PsOffCanvasWindow>
        </>
    );
};
export default withRouter(NOCs);
