import React, { useEffect, useState } from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { _TodayCollectionSummary } from "../../../../../models/dashboard";
import CollectionPieChart from "./collectionPieChart";
import { Spin, Tabs } from "antd";
import ChartTypeWithData from "./chartTypeWithData";

const AmountCollectionChart = (props) => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [dataList, setDataList] = useState([]);

  const [monthData, setMonthData] = useState([]);
  const [monthDataList, setMonthDataList] = useState([]);
  const [activeKey, setActiveKey] = useState("today");

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (getTotal(dataList) > 0) setActiveKey("today");
    else if (getTotal(monthDataList) > 0) setActiveKey("month");
  }, [dataList, monthDataList]);

  const getTotal = (dl) => {
    let total = 0;
    dl.map(
      (item) =>
        item.amount && (total = parseFloat(total) + parseFloat(item.amount))
    );
    return parseFloat(total).toFixed(2);
  };

  const loadData = () => {
    setLoader(true);
    _TodayCollectionSummary().then((res) => {
      if (res.status == "1") {
        let d = [];
        res.data.map((item) =>
          d.push({ type: item.method_name, value: parseFloat(item.amount) })
        );
        let m = [];
        res.month.map((item) =>
          m.push({ type: item.method_name, value: parseFloat(item.amount) })
        );
        setData(d);
        setMonthData(m);
        setDataList(res.data);
        setMonthDataList(res.month);
      }
      setLoader(false);
    });
  };

  return (
    <>
      <Card>
        <Card.Header className="fw-400">
          Collection Summary
          <spin className="float-end antd-tab-mb-0">
            <Tabs
              size="small"
              type="card"
              activeKey={activeKey}
              onChange={(k) => setActiveKey(k)}
              items={[
                { label: "Today", key: "today" },
                { label: "This Month", key: "month" },
              ]}
              className="mb-0 pb-0"
            />
          </spin>
        </Card.Header>
        <Card.Body style={{ minHeight: "200px" }}>
          <Spin spinning={loader}>
            {activeKey == "today" && (
              <ChartTypeWithData data={data} dataList={dataList} />
            )}
            {activeKey == "month" && (
              <ChartTypeWithData data={monthData} dataList={monthDataList} />
            )}
          </Spin>
        </Card.Body>
      </Card>
    </>
  );
};
export default AmountCollectionChart;
