import React, { useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";

import { Col, Row ,Button} from "react-bootstrap";

import axios from "axios";
import PsContext from "../../../../context";
import ModuleAccess from "../../../../context/moduleAccess";
import { checkBalance } from "../../../../models/resellerSms";
import { CardFixedTop, PsSelect } from "../../../../utils";
import { ServiceUrl } from "../../../../utils/serviceUrl";
import AmountCollectionChart from "./AmountCollectionChart";
import { liststDepartments } from "../../../../models/hr";
import { Bar } from '@ant-design/charts';
import { Pie } from "@ant-design/plots";
import { Card, Row as AntRow, Col as AntColm, Spin, Select } from 'antd'
import moment from 'moment'
import { Column } from '@ant-design/plots';
const DashboardAnalytics = (props) => {
    const context = useContext(PsContext);
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [smsBalance, setSmsBalance] = useState(null);
    const [count, setCount] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [allData, setAllData] = useState([])
    const [hourlyAttData, setHourlyAttData] = useState([]);
    const [courseWiseAttendance, setCourseWiseAttendance] = useState([]);
    const [courseWiseAttendance2, setCourseWiseAttendance2] = useState([]);
    const [totalAttCount, setTotalAttCount] = useState([]);
    const [totalGenderCount, setTotalGenderCount] = useState([]);
    const [totalCtypeGenderCount, setTotalCtypeGenderCount] = useState([]);
    const [courseWiseStrengthCount, setCourseWiseStrengthCount] = useState([]);
    const [courseWiseStrengthCount2, setCourseWiseStrengthCount2] = useState([]);
    const [courseWiseAttData, setCourseWiseAttData] = useState([]);
    const [selCourse, setSelCourse] = useState("");

    useEffect(() => {
        loadData();
        if (context.allowedAccess("dashboard_top_sms_count", "action_read")) {
            let regex = /^[0-9]+$/;
            checkBalance().then(
                (res) => res && regex.test(res) && setSmsBalance(res)
            );
        }
    }, []);

    useEffect(() => {
        liststDepartments();
    }, []);

    const HourlyAttConfig = {
        data: hourlyAttData,
        isStack: true,
        xField: 'hour_value',
        yField: 'stu_count',
        seriesField: 'leave_type',
        label: {

            position: 'middle',

            layout: [

                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
        xAxis: {
            title: {
                text: 'Hour/Period', // X-axis label
            },
        },
        yAxis: {
            title: {
                text: 'Student Count', // Y-axis label
            },
        },
    };
    const CourseWiseAttDataConfig = {
        data: courseWiseAttData,
        isStack: true,
        xField: 'hour_value',
        yField: 'stu_count',
        seriesField: 'leave_type',
        label: {

            position: 'middle',

            layout: [

                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
        xAxis: {
            title: {
                text: 'Hour/Period', // X-axis label
            },
        },
        yAxis: {
            title: {
                text: 'Student Count', // Y-axis label
            },
        },
    };
    const CourseWiseAttConfig = {
        data: courseWiseAttendance,
        isStack: true,
        xField: 'count',
        yField: 'course_name',
        seriesField: 'type',
        label: {

            position: 'middle',

            layout: [

                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },

    };
    const CourseWiseAttConfig2 = {
        data: courseWiseAttendance2,
        isStack: false,
        xField: 'count',
        yField: 'type',
        // seriesField: 'type',
        label: {

            position: 'middle',

            layout: [

                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },

    };
    const courseWiseStrengthConfig = {
        data: courseWiseStrengthCount,
        isStack: true,
        xField: 'stu_count',
        yField: 'course_name',
        seriesField: 'gender',
        label: {

            position: 'middle',

            layout: [

                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },

    };
    const courseWiseStrengthConfig2 = {
        data: courseWiseStrengthCount2,
        isStack: true,
        xField: 'stu_count',
        yField: 'gender',
        // seriesField: 'gender',
        label: {

            position: 'middle',

            layout: [

                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },

    };
    const totalAttCountConfig = {

        appendPadding: [5, 5, 5, 5],
        data: totalAttCount,
        angleField: 'value',
        colorField: 'type',
        radius: 0.9,
        label: {
            type: 'inner',
            offset: '-30%',
            content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
            style: {
                fontSize: 16,
                textAlign: 'center',
            },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],

    }
    const totalGenderCountConfig = {

        appendPadding: [5, 5, 5, 5],
        data: totalGenderCount,
        angleField: 'value',
        colorField: 'type',
        radius: 0.9,
        label: {
            type: 'inner',
            offset: '-30%',
            content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
            style: {
                fontSize: 16,
                textAlign: 'center',
            },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],

    }
    const totalCtypeGenderCountConfig = {
        data: totalCtypeGenderCount,
        isStack: true,
        xField: 'coursetype',
        yField: 'stu_count',
        seriesField: 'gender',
        label: {

            position: 'middle',

            layout: [

                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
        xAxis: {
            title: {
                text: 'Course Type', // X-axis label
            },
        },
        yAxis: {
            title: {
                text: 'Gender', // Y-axis label
            },
        },
    };

    const loadData = () => {
        setLoader(true);
        axios.post(ServiceUrl.DASHBOARD_ANALYTICS.LIST_ANALYTICS_DATA).then((res) => {
            if (res["data"].status == "1") {
                var resData = res["data"].data;
                setAllData(resData);
                var hAttData = getHourlyAttData(resData['attendance_data']);
                setHourlyAttData(hAttData);
                setCourseWiseAttendance(getCourseWiseAttData(resData['cousewise_attendance_data']))
                setCourseWiseAttendance2(getCourseWiseAttData2(resData['cousewise_attendance_data']))
                setTotalAttCount(getTotalAttCount(resData['cousewise_attendance_data']))
                setTotalGenderCount(getTotalGenderCount(resData['total_gender_strength']))
                // var hGenData = getTotalCtypeGenderCount(resData['total_course_type_strength']);
                // setTotalCtypeGenderCount(hGenData);
                setTotalCtypeGenderCount(getTotalCtypeGenderCount(resData['total_course_type_strength']))
                setCourseWiseStrengthCount(getCourseWiseStrengthData(resData['course_wise_strength']));
                setCourseWiseStrengthCount2(getCourseWiseStrengthData2(resData['course_wise_strength']));
                setCourseWiseAttData(getCourseWiseAttDataa(resData['attendance_data_course']));

                console.log(res["data"].data);
            }
            setLoader(false);
        });
    };

    const getHourlyAttData = (data) => {

        var filteredData = data.map(({ hour_value, leave_type, stu_count }) => ({
            hour_value,
            leave_type: leave_type === 'p' ? 'Present' : leave_type === 'a' ? 'Absent' : 'Absent',
            stu_count: parseInt(stu_count), // Convert stu_count to integer
        }));


        return filteredData;
    }

    const getCourseWiseAttDataa = (arr) => {
        // var extractedData =  arr.filter((obj)=>obj.course_id==selCourse).map(item => ({
        var filteredData = arr.filter((obj) => obj.course == selCourse).map(({ hour_value, leave_type, stu_count }) => ({
            hour_value,
            leave_type: leave_type === 'p' ? 'Present' : leave_type === 'a' ? 'Absent' : 'Absent',
            stu_count: parseInt(stu_count), // Convert stu_count to integer
        }));
        /// console.log('mydata',arr,selCourse,filteredData)

        return filteredData;
    }

    const getTotalCtypeGenderCount = (data) => {
        /*   const filteredData = data.map(({ coursetype, gender, stu_count }) => ({
            type: `${coursetype}-${gender}`, // Combine coursetype and gender for the 'type' field
            value: parseInt(stu_count), // Convert stu_count to integer
          })); */
        // console.log('cwise',filteredData)
        var fData = [];
        data.forEach(item => {
            fData.push({
                coursetype: item.coursetype,
                gender: item.gender,
                stu_count: parseInt(item.stu_count)
            })
        })
        return fData;
    };

    const liststDepartments = () => {
        axios.get(ServiceUrl.SETTINGS.LIST_COURSES).then((res) => {
            if (res["data"].status == "1") {
                setDepartments(res["data"].data);
                /*  var acDepartmentData = res["data"].data.sort(
                     (a, b) => parseInt(b.academic_department) - parseInt(a.academic_department)
                 ); */
                // console.log('dep', res["data"].data)

                // setSelDepartment(acDepartmentData[0].academic_department);

            } else {
                toast.error(res["data"].message || "Error");
            }
        });
    };

    const getCourseWiseAttData = (arr) => {
        var extractedData = arr.map(item => ({
            course_name: item.course_name + "(" + (item.coursetype == 'regular' ? 'R' : 'SF') + ")",
            present_count: item.present_count,
            absent_count: item.absent_count
        }));
        var finalData = [];
        extractedData.forEach(item => {
            finalData.push({
                course_name: item.course_name,
                type: 'Present',
                count: parseInt(item.present_count)
            })
            finalData.push({
                course_name: item.course_name,
                type: 'Absent',
                count: parseInt(item.absent_count)
            })
        })
        console.log('att', finalData)
        return finalData;
    }
    const getCourseWiseAttData2 = (arr) => {
        var extractedData = arr.filter((obj) => obj.course_id == selCourse).map(item => ({
            // course_name: item.course_name + "(" + (item.coursetype == 'regular' ? 'R' : 'SF') + ")",
            present_count: item.present_count,
            absent_count: item.absent_count
        }));
        var finalData = [];
        extractedData.forEach(item => {
            finalData.push({
                //  course_name: item.course_name,
                type: 'Present',
                count: parseInt(item.present_count)
            })
            finalData.push({
                //    course_name: item.course_name,
                type: 'Absent',
                count: parseInt(item.absent_count)
            })
        })
        console.log('att2', extractedData, finalData, arr)
        return finalData;
    }
    const getCourseWiseStrengthData = (arr) => {
        var fData = [];
        arr.forEach(item => {
            fData.push({
                course_name: item.degreename + " " + item.name + "(" + (item.coursetype == 'regular' ? 'R' : 'SF') + ")",
                gender: item.gender,
                stu_count: parseInt(item.stu_count)
            })
        })
        return fData;
    }
    const getCourseWiseStrengthData2 = (arr) => {
        var fData = [];
        arr.forEach(item => {
            fData.push({
                //course_name: item.degreename + " " + item.name + "(" + (item.coursetype == 'regular' ? 'R' : 'SF') + ")",
                gender: item.gender,
                stu_count: parseInt(item.stu_count)
            })
        })
        return fData;
    }
    const getTotalAttCount = (arr) => {
        let presentTotal = 0;
        let absentTotal = 0;

        // Loop through the array to calculate the sums
        arr.forEach(item => {
            presentTotal += item.present_count;
            absentTotal += item.absent_count;
        });
        var totals = [];
        totals.push({ type: 'Present', value: presentTotal })
        totals.push({ type: 'Absent', value: absentTotal })

        return totals;
    }
    const getTotalGenderCount = (arr) => {
        let femaleTotal = 0;
        let maleTotal = 0;

        // Loop through the array to calculate the sums
        arr.forEach(item => {
            if (item.gender === 'female') {
                femaleTotal += parseInt(item.stu_count);
            } else if (item.gender === 'male') {
                maleTotal += parseInt(item.stu_count);
            }
        });

        const totals = [
            { type: 'Female', value: femaleTotal },
            { type: 'Male', value: maleTotal }
        ];

        // console.log('totals:', totals);
        return totals;
    };
    const reloadData = (course_id) => {

    }

    return (
        <>
            <Card title={
                <>
                    <Row>
                        <Col md={6}>Dashboard</Col>
                        <Col md={4}>
                            <Select
                                showSearch
                                placeholder="Select Programme"
                                // name="academic_department"
                                allowClear={true}
                                //mode="multiple"
                                optionFilterProp="children"
                                style={{
                                    width: "60%", float: "right"
                                }}
                                onChange={(v) => {
                                    setCourseWiseAttendance2([]);setCourseWiseStrengthCount2([]);setCourseWiseAttData([])
                                    setSelCourse(v);}}
                            >
                                <Select.Option value="">ALL</Select.Option>
                                {
                                    departments.map(item =>
                                        <Select.Option value={item.course_id}

                                        >
                                            {item.degreename}-{item.name}-({(item.coursetype == "self" ? "SF" : "R")})
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        </Col>
                        <Col md={2}><Button varient="primary" onClick={(e)=>{loadData(selCourse)}} >View</Button></Col>
                    </Row>

                </>
            } />
            {!selCourse && (<div className="container">
                <Row className="mt-3">
                    <Col md={6}>
                        <ModuleAccess
                            module="today_month_coll_summary_chart"
                            action="action_read"
                        >
                            <AmountCollectionChart />
                        </ModuleAccess>
                        <Row className="mt-3">
                            <Col md={12}>
                                <Spin spinning={loader}>
                                    <Card title="Attendance Total" bodyStyle={{ height: '300px' }}>
                                        {totalAttCount.length > 0 && (<Pie
                                            data={totalAttCount}
                                            radius={0.8}
                                            angleField="value"
                                            colorField="type"
                                            //width={400}
                                            height={200}
                                            label={{
                                                type: "inner",
                                                offset: "-30%",
                                                content: ({ percent }) =>
                                                    `${(percent * 100).toFixed(0)}%`,
                                                style: {
                                                    fontSize: 14,
                                                    textAlign: "center",
                                                },
                                            }}
                                        // {...totalAttCountConfig}
                                        />)}
                                    </Card>
                                </Spin>
                            </Col>

                        </Row>
                    </Col>
                    <Col md={6}>
                        <Spin spinning={loader}>
                            <Card title={<>Hourly Attendance on {moment(allData.last_attendance_date).format("DD-MMM-YYYY")}</>}>

                                {hourlyAttData.length > 0 && (<Column {...HourlyAttConfig} />)}

                            </Card>
                        </Spin>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Spin spinning={loader}>
                        <Col md={12}>
                            <Card title={<>All Hour Present/Absent on {moment(allData.last_attendance_date).format("DD-MMM-YYYY")}</>}>
                                {courseWiseAttendance.length > 0 && (<Bar {...CourseWiseAttConfig} />)}
                            </Card>
                        </Col>
                    </Spin>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                        <Spin spinning={loader}>
                            <Card title="Gender Total" bodyStyle={{ height: '300px' }}>
                                {totalGenderCount.length > 0 && (<Pie
                                    data={totalGenderCount}
                                    radius={0.8}
                                    angleField="value"
                                    colorField="type"
                                    //width={400}
                                    height={200}
                                    label={{
                                        type: "inner",
                                        offset: "-30%",
                                        content: ({ percent }) =>
                                            `${(percent * 100).toFixed(0)}%`,
                                        style: {
                                            fontSize: 14,
                                            textAlign: "center",
                                        },
                                    }}
                                // {...totalAttCountConfig}
                                />)}
                            </Card>
                        </Spin>
                    </Col>

                    <Col md={6}>
                        <Spin spinning={loader}>
                            <Card title="Course type wise total Gender Strength">

                                {totalCtypeGenderCount.length > 0 && (<Column {...totalCtypeGenderCountConfig} />)}

                            </Card>
                        </Spin>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={12}>
                        <Spin spinning={loader}>
                            <Card title={<>Coursewise Gender Strength</>}>
                                {courseWiseStrengthCount.length > 0 && (<Bar {...courseWiseStrengthConfig} />)}
                            </Card>
                        </Spin>
                    </Col>
                </Row>
            </div>)}
            {selCourse && (<>

                <Row className="mt-3">
                    <Spin spinning={loader}>
                        <Col md={12}>
                            <Card title={<>All Hour Present/Absent on {moment(allData.last_attendance_date).format("DD-MMM-YYYY")}</>}>
                                {courseWiseAttendance2.length > 0 && (<Bar {...CourseWiseAttConfig2} />)}
                            </Card>
                        </Col>
                    </Spin>
                </Row>
                <Row className="mt-3">
                    <Col md={12}>
                        <Spin spinning={loader}>
                            <Card title={<>Coursewise Gender Strength</>}>
                                {courseWiseStrengthCount2.length > 0 && (<Bar {...courseWiseStrengthConfig2} />)}
                            </Card>
                        </Spin>
                    </Col>
                </Row>
                <Row className="mt-3">

                    <Col md={6}>
                        <Spin spinning={loader}>
                            <Card title={<>Hourly Attendance  on {moment(allData.last_attendance_date).format("DD-MMM-YYYY")}</>}>

                                {courseWiseAttData.length > 0 && (<Column {...CourseWiseAttDataConfig} />)}

                            </Card>
                        </Spin>
                    </Col>

                </Row>

            </>)}

        </>
    );
};

export default withRouter(DashboardAnalytics);
