import React from "react";
import { Form } from "react-bootstrap";
import { upperCase } from ".";

const PsSectionListDropdown = (props) => {
  const items = ["a", "b", "c", "d", "e"];

  const loadSections = (rawData = false) => {
    let rv = [];
    items.map((item) => {
      rv.push(
        <option
          value={item.id}
          selected={props.value && item == props.value ? "selected" : ""}
        >
          {upperCase(item)}
        </option>
      );
    });
    return rv;
  };

  const handleChange = (e) => {
    if (props.onChange) props.onChange(e.target.value);
  };

  return (
    <>
      <Form.Control
        as="select"
        className="fw-bold form-select form-select-md"
        name="section"
        size={props.size || "sm"}
        onChange={(e) => handleChange(e)}
        style={props.styles || { position: "relative", width: "80px" }}
      >
        {props.showAll && <option value="">-All-</option>}
        {loadSections()}
      </Form.Control>
    </>
  );
};

export default PsSectionListDropdown;
